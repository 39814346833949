import React, { Component } from "react";
import axios from "axios";
import Layout from "../../components/layouts/main-layout";
import Container from "../../components/container";
import Spinner from "../../components/spinner";
import Arrow from "../../components/icons/arrow";
import ConnectedLessons from "../../components/lesson-result-connected-lessons";
import moment from "moment";

const initialState = {
  formData: {
    firstName: "",
    lastName: "",
    street: "",
    postCode: "",
    city: "",
    email: "",
    phone: "",
    height: "",
    weight: "",
    legal_select: "is-legal",
    birthdate: "",
    gender: "FEM",
    guestComment: "",
    stayFrom: "",
    stayTo: "",
    event_id: null,
    event_title_loading: null,
    event_title: null,
    termsAccepted: false,
    privacyAccepted: false,
  },
  formSent: false,
  isLoading: true,
  lessonFound: null,
  seatsAvailable: null,
  lesson: null,
  connectedLessons: [],
};

class Anmeldung extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    const event = urlParams.get("event");

    this.state = {
      ...initialState,
      formData: {
        ...initialState.formData,
        event_id: event,
      },
    };

    if (event !== null) {
      this.checkAvailability();
    } else {
      this.state = {
        ...this.state,
        isLoading: false,
      };
    }
  }

  getConnectedLessons = async (event) => {
    const res = await axios.get(
      `${process.env.GATSBY_TIDENS_API_URL}/lessons/connected/${event}`
    );
    console.log(res);
    return res.data.data;
  };

  seatsStillAvailable = async () => {
    // IF NO EVENT, REGISTRATION IS ALWAYS OPEN
    if (this.state.formData.event_id === null) return true;

    const res = await axios.get(
      `${process.env.GATSBY_TIDENS_API_URL}/lessons/${
        this.state.formData.event_id
      }`
    );

    if (res.data.msg_code === "LESSON_SEATS_AVAILABLE") {
      return true;
    } else {
      return false;
    }
  };

  checkAvailability = () => {
    axios
      .get(
        `${process.env.GATSBY_TIDENS_API_URL}/lessons/${
          this.state.formData.event_id
        }`
      )
      .then((res) => {
        this.setState({ isLoading: false });

        // Wenn Kurs nicht gefunden
        if (res.data.msg_code === "LESSON_NOT_FOUND") {
          this.setState({
            lessonFound: false,
          });
        }

        // Kurs gefunden aber keine Plätze
        if (res.data.msg_code === "LESSON_NO_SEATS_AVAILABLE") {
          this.setState({
            ...this.state,
            lessonFound: true,
            seatsAvailable: false,
            lesson: res.data.data,
            formData: {
              ...this.state.formData,
              event_title: res.data.data.service.name,
              event_date:
                moment(res.data.data.planned_from).format(
                  "DD.MM.YYYY | HH:mm "
                ) +
                " - " +
                moment(res.data.data.planned_to).format("HH:mm"),
            },
          });
        }

        // Kurs gefunden und freie Kursplätze
        if (res.data.msg_code === "LESSON_SEATS_AVAILABLE") {
          this.getConnectedLessons(res.data.data.id).then((res) => {
            this.setState({
              ...this.state,
              connectedLessons: res,
            });
          });
          console.log(res);
          this.setState({
            ...this.state,
            lessonFound: true,
            seatsAvailable: true,
            lesson: res.data.data,
            formData: {
              ...this.state.formData,
              event_title: res.data.data.service.name,
              event_date:
                moment(res.data.data.planned_from).format(
                  "DD.MM.YYYY | HH:mm "
                ) +
                " - " +
                moment(res.data.data.planned_to).format("HH:mm"),
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  };

  handleClick = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    let seatsAvailable;
    this.seatsStillAvailable().then((res) => {
      seatsAvailable = res;
      console.log(res);

      if (seatsAvailable === true) {
        axios
          .post(
            `${process.env.GATSBY_TIDENS_API_URL}/guests`,
            this.state.formData
          )
          .then((res) => {
            this.setState({
              ...this.initailState,
              formSent: true,
              isLoading: false,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              ...this.initailState,
              formSent: true,
              isLoading: false,
            });
          });
      } else {
        this.setState({
          ...this.state,
          isLoading: false,
          seatsAvailable: false,
        });
      }
    });
  };

  render() {
    if (this.state.isLoading === true) {
      return (
        <Layout>
          <Container>
            <Spinner />
          </Container>
        </Layout>
      );
    }

    if (this.state.isLoading === false) {
      // Kurs nicht gefunden
      if (
        this.state.lessonFound === false &&
        this.state.formData.event_id !== null
      ) {
        return (
          <Layout>
            <Container>
              <h1>Kurs nicht gefunden</h1>
              <p>
                Leider haben wir Deinen gewünschten Kurs nicht gefunden. Bitte
                überprüfe Deinen Buchungslink. Ansonsten nimm bitte Kontakt mit
                uns auf.
              </p>
            </Container>
          </Layout>
        );
      }

      // Kurs gefunden, aber keine freien Plätze
      if (this.state.seatsAvailable === false) {
        return (
          <Layout>
            <Container>
              <h1>Kurs ausgebucht</h1>
              <div>
                <h2>{this.state.formData.event_title}</h2>
                <h3 className="pt-0">{this.state.formData.event_date}</h3>
              </div>
              <p className="py-4">
                Leider ist dieser Kurs bereits ausgebucht. Bitte kontaktiere uns
                für einen alternativen Termin.
              </p>
            </Container>
          </Layout>
        );
      }

      // Kurs gefunden und freie Plätze
      if (
        (this.state.seatsAvailable === true && this.state.formSent === false) ||
        (this.state.formData.event_id === null && this.state.formSent === false)
      ) {
        return (
          <Layout>
            <Container>
              <h1>Anmeldung</h1>
              <div>
                <h2>{this.state.formData.event_title}</h2>
                <h3 className="pt-0">
                  {this.state.connectedLessons.length > 0
                    ? moment(this.state.lesson.planned_from).format(
                        "DD.MM.YYYY"
                      ) +
                      " - " +
                      moment(
                        this.state.connectedLessons[
                          this.state.connectedLessons.length - 1
                        ].planned_from
                      ).format("DD.MM.YYYY")
                    : this.state.formData.event_date}
                </h3>
                {this.state.connectedLessons.length > 0 ? (
                  <div className="pb-4">
                    <ConnectedLessons lessons={this.state.connectedLessons} lesson={this.state.lesson}/>
                  </div>
                ) : null}
              </div>
              <p>
                Bevor Du deinen Kurs bei uns beginnen oder Material ausleihen
                kannst, benötigen wir noch einige Daten von Dir. Bitte fülle{" "}
                <strong>für jede Person</strong> das folgende Formular aus:
              </p>
              <div className="mt-6 mx-0 lg:mx-[25%]">
                <form onSubmit={this.handleClick}>
                  <div className="grid grid-cols-12">
                    <label
                      htmlFor="firstName"
                      className="col-span-12 lg:col-span-3"
                    >
                      Vorname:
                    </label>

                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.firstName}
                      onChange={this.handleChange}
                      required
                      minLength={2}
                      maxLength={50}
                    />
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="lastName"
                      className="col-span-12 lg:col-span-3"
                    >
                      Nachname:
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.lastName}
                      onChange={this.handleChange}
                      required
                      minLength={2}
                      maxLength={50}
                    />
                  </div>
                  <div className="pt-6 font-bold">
                    Heimat- / Rechnungsadresse
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="street"
                      className="col-span-12 lg:col-span-3"
                    >
                      Straße:
                    </label>
                    <input
                      type="text"
                      name="street"
                      id="street"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.street}
                      onChange={this.handleChange}
                      required
                      minLength={5}
                      maxLength={95}
                    />
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="postCode"
                      className="col-span-12 lg:col-span-3"
                    >
                      PLZ:
                    </label>
                    <input
                      type="text"
                      name="postCode"
                      id="postCode"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.postCode}
                      onChange={this.handleChange}
                      required
                      minLength={4}
                      maxLength={9}
                    />
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label htmlFor="city" className="col-span-12 lg:col-span-3">
                      Ort:
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.city}
                      onChange={this.handleChange}
                      required
                      minLength={2}
                      maxLength={35}
                    />
                  </div>
                  <div className="pt-6 font-bold">Kontaktdaten</div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="email"
                      className="col-span-12 lg:col-span-3"
                    >
                      E-Mail:
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.email}
                      onChange={this.handleChange}
                      required
                      minLength={7}
                      maxLength={65}
                    />
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="phone"
                      className="col-span-12 lg:col-span-3"
                    >
                      Handynummer:
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.phone}
                      onChange={this.handleChange}
                      required
                      minLength={10}
                      maxLength={15}
                      placeholder="+49152123456"
                    />
                  </div>
                  <div className="pt-6 font-bold">
                    Personendaten (benötigt für die Kursplanung)
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="height"
                      className="col-span-12 lg:col-span-3"
                    >
                      Größe (cm):
                    </label>
                    <input
                      type="number"
                      name="height"
                      id="height"
                      min="100"
                      max="220"
                      required
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.height}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="weight"
                      className="col-span-12 lg:col-span-3"
                    >
                      Gewicht (kg):
                    </label>
                    <input
                      type="number"
                      name="weight"
                      id="weight"
                      min={25}
                      max={150}
                      required
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.weight}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="legal_select"
                      className="col-span-12 lg:col-span-3"
                    >
                      Volljährig:
                    </label>
                    <div className="flex col-span-12 lg:col-span-9 ml-0 lg:ml-2">
                      <div className="form-check form-check-inline ">
                        <input
                          checked={
                            this.state.formData.legal_select === "is-legal"
                          }
                          className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-info checked:border-info  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="radio"
                          name="legal_select"
                          id="is-legal"
                          value="is-legal"
                          onChange={this.handleChange}
                        />
                        <label
                          className="form-check-label inline-block text-gray-800"
                          htmlFor="is-legal"
                        >
                          Ja
                        </label>
                      </div>
                      <div className="form-check form-check-inline ml-3">
                        <input
                          checked={
                            this.state.formData.legal_select === "is-not-legal"
                          }
                          className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-info checked:border-info focus:outline-none  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="radio"
                          name="legal_select"
                          id="is-not-legal"
                          value="is-not-legal"
                          onChange={this.handleChange}
                        />
                        <label
                          className="form-check-label inline-block text-gray-800"
                          htmlFor="legalAge2"
                        >
                          Nein
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.state.formData.legal_select === "is-not-legal" ? (
                    <div className="mt-4 grid grid-cols-12">
                      <label
                        htmlFor="birthdate"
                        className="col-span-12 lg:col-span-3"
                      >
                        Geburtsdatum:
                      </label>
                      <input
                        type="date"
                        name="birthdate"
                        id="birthdate"
                        className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                        value={this.state.formData.birthdate}
                        onChange={this.handleChange}
                        required={this.state.formData === "is-not-legal"}
                      />
                    </div>
                  ) : null}
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="gender"
                      className="col-span-12 lg:col-span-3"
                    >
                      Geschlecht:
                    </label>
                    <select
                      name="gender"
                      id="gender"
                      className="px-4 py-3 rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.gender || "FEM"}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="FEM">Weiblich</option>
                      <option value="MAL">Männlich</option>
                      <option value="DIV">Divers</option>
                      <option value="NON">Möchte ich nicht sagen</option>
                    </select>
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="stayFrom"
                      className="col-span-12 lg:col-span-3"
                    >
                      Aufenthalt von:
                    </label>
                    <input
                      type="date"
                      name="stayFrom"
                      id="stayFrom"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.stayFrom}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="stayTo"
                      className="col-span-12 lg:col-span-3"
                    >
                      Aufenthalt bis:
                    </label>
                    <input
                      type="date"
                      name="stayTo"
                      id="stayTo"
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                      value={this.state.formData.stayTo}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="mt-4 grid grid-cols-12">
                    <label
                      htmlFor="guestComment"
                      className="col-span-12 lg:col-span-3"
                    >
                      Kommentar / Nachricht:
                    </label>
                    <textarea
                      name="guestComment"
                      id="guestComment"
                      cols="35"
                      rows="5"
                      value={this.state.formData.guestComment}
                      onChange={this.handleChange}
                      className="rounded-md ml-0 lg:ml-2 focus:border-info bg-secondary focus:bg-white col-span-12 lg:col-span-9"
                    />
                  </div>
                  <div className="mt-4">
                    <input
                      name="termsAccepted"
                      checked={this.state.formData.termsAccepted}
                      onChange={this.handleChange}
                      type="checkbox"
                      required
                      className="rounded text-info mr-2"
                    />
                    <label htmlFor="termsAccepted">
                      Ich akzeptiere die{" "}
                      <a href="/agb" target="_blank" className="text-info">
                        AGB
                      </a>
                      .
                    </label>
                  </div>
                  <div className="mt-4">
                    <input
                      name="privacyAccepted"
                      checked={this.state.formData.privacyAccepted}
                      onChange={this.handleChange}
                      type="checkbox"
                      required
                      className="rounded text-info mr-2"
                    />
                    <label htmlFor="privacyAccepted">
                      Ich akzeptiere, dass meine Daten zur Durchführung meiner
                      gebuchten Leistungen verarbeitet und gespeichert werden.
                      Die{" "}
                      <a
                        href="/datenschutz"
                        target="_blank"
                        className="text-info"
                      >
                        Datenschutzerklärung
                      </a>{" "}
                      habe ich gelesen.
                    </label>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className={`inline-flex items-center rounded-full px-5 py-2 mt-4 border-primary border-2 bg-primary text-white hover:font-bold ${
                        this.state.isLoading ? "cursor-not-allowed" : ""
                      }`}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading === true && <Spinner />}
                      {this.state.lessonFound === true
                        ? "Buchen"
                        : "Senden"}{" "}
                      <Arrow color="primary" className="ml-2" />
                    </button>
                  </div>
                </form>
              </div>
            </Container>
          </Layout>
        );
      }

      if (this.state.formSent === true) {
        return (
          <Layout>
            <Container>
              <div>
                <h1>Anmeldung erfolgreich</h1>
                <p>
                  <strong>
                    Vielen Dank für die Anmeldung an unserer Station!
                  </strong>{" "}
                  Falls Du noch keinen Starttermin erhalten hast, melden wir uns
                  ca. 2 Tage vor deinem Kurs noch einmal bei dir, um dir den
                  genauen Startzeitpunkt mitzuteilen.
                </p>
                <p>Bis bald, dein Tidens Surfhuus Team.</p>

                <button
                  type="reset"
                  className={`inline-flex items-center rounded-full px-5 py-2 mt-4 border-primary border-2 bg-primary text-white hover:font-bold 
                      }`}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      formData: {
                        ...initialState.formData,
                        event_id: this.state.formData.event_id,
                        event_title: this.state.formData.event_title
                      }, 
                      formSent: false,
                    });
                    if (this.state.formData.event_id !== null) {
                      this.setState({
                        isLoading: true
                      })
                      this.checkAvailability()};
                  }}
                >
                  Weitere Person anmelden{" "}
                  <Arrow color="primary" className="ml-2" />
                </button>
              </div>
            </Container>
          </Layout>
        );
      }
    }

    return null;
  }
}

export default Anmeldung;
